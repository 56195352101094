<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        v-if="$store.getters.current.permissions.USERS_WRITE"
        color="primary"
        elevation="4"
        fab
        large
        class="fab-new"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h6 primary white--text mb-6">
        Benutzerkonto hinzufügen
      </v-card-title>
      <v-form v-if="!newUser" @submit.prevent="findUser">
        <v-card-text>
          Der Zugang zu <strong>"{{ appTitle }}"</strong> erfordert einen gültigen CROSSMEDIA
          Account.<br />
          Gib die E-Mail Adresse der Person ein, die einen Zugang
          erhalten soll.
        </v-card-text>

        <v-text-field
          v-model.trim="newUserEmail"
          type="email"
          label="E-Mail Adresse"
          :hint="hints.email"
          persistent-hint
          prepend-inner-icon="mdi-at"
          outlined
          clearable
          required
          class="mx-4"
          @keyup="hints.email = ''"
          autofocus
        ></v-text-field>

        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn  text color="primary" @click="dialog = false">
            {{ trl("cancel") }}
          </v-btn>
          <v-btn color="primary" class="white--text" type="submit"
            >Benutzer in XM Accounts suchen</v-btn
          >
        </v-card-actions>
      </v-form>
      <v-form v-else>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">Benutzer gefunden</div>
            <v-list-item-title class="text-h5 mb-1">
              {{ newUser.firstname }} {{ newUser.lastname }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ newUser.email }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-avatar color="white" size="72">
          <v-img contain :src="newUser.avatar" />
        </v-list-item-avatar>
         
        </v-list-item>
        <v-col cols="12">
          <v-select
            v-model="newUserRole"
            label="Benutzerrolle festlegen"
            item-value="id"
            item-text="name"
            :items="this.$store.getters.roles"
            :hint="hints.role"
            outlined
            v-on:change="getRoleInfo()"
          ></v-select>
          <v-select
            v-if="divisionSelector"
            v-model="newUserDivisions"
            item-value="id"
            item-text="name"
            outlined
            :items="this.$store.getters.divisions"
            multiple
          >

          </v-select>
        </v-col>

        <v-divider></v-divider>

        <v-card-actions class="mb-4">
          <v-spacer />
          <v-btn  text color="primary" @click="cancelUser">
            {{ trl("cancel") }}
          </v-btn>

          <v-btn color="primary" @click="addUser">
            Benutzer hinzufügen
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import { trl } from "@/utils/strings";

export default {
  data() {
    return {
      dialog: false,
      divisionSelector: false,
      newUserEmail: "",
      newUserRole: null,
      newUserDivisions: null,
      hints: {
        email: "",
        role: "Wähle eine Rolle, um mehr Informationen zu erhalten"
      },
      newUser: null,
    };
  },
  methods: {
    async findUser() {
      this.newUser = null;
      if (this.newUserEmail === "") {
        this.hints.email = "Bitte gib eine E-Mail Adresse ein.";
        return;
      }

      let isUser = this.$store.getters.users.find(
        (u) => u.email === this.newUserEmail
      );

      if (isUser) {
        this.hints.email = `${isUser.firstname} ${isUser.lastname} hat bereits ein Forecast-Konto.`;
        return;
      }

      const res = await fetch("/api/person/?email=" + this.newUserEmail, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.$store.state.me.token
        }});

      if(!res.ok) {
        this.hints.email = "ERROR: " + res.status + " | " + res.statusText;
        return;
      }

      const json = await res.json();
      
      if (json.email) {
        let u = json;
        
        u.picture = u.tea_id + ".jpg";
        u.avatar = process.env.VUE_APP_AVATAR_URL + "/" + u.picture;
        this.newUser = u;
      } else if (json.message) {
        this.hints.email =
          `Message from XM Accounts: ${json.message}`;
        return;
      } else {
         this.hints.email =
          "Kein CROSSMEDIA-Account mit dieser E-Mail Adresse vorhanden.";
        return;
      }
    },

    addUser() {
      this.hints.role = "";
      if (!this.newUserRole) {
        this.hints.role = "Bitte wähle eine Benutzerrolle aus.";
        return;
      }

      this.newUser.tenant_id = this.$store.getters.current.id;
      this.newUser.role_id = this.newUserRole;
      this.$store.dispatch("saveUser", this.newUser);
      this.newUserEmail = "";
      this.newUserRole = null;
      this.newUser = null;
      this.dialog = false;
    },
    cancelUser() {
      this.newUserEmail = "";
      this.newUser = null;
    },
    getRoleInfo(){
      let role = this.$store.getters.roles.find(r => r.id === this.newUserRole);
      //this.divisionSelector = !role.permissions.includes("FC_TENANT_READ") && role.permissions.includes("FC_DIVISION_READ");
      this.hints.role = role.description;
    },
    trl,
  },
  computed: {
    appTitle() {
      return process.env.VUE_APP_TITLE + " " + process.env.VUE_APP_VERSION;
    }
  },
  created(){
    //console.log(this.$store.getters.roles);
  }
};
</script>

