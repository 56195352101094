<template>
  <v-layout row wrap>
      <v-spacer />
     
      <v-flex xs12 sm6 lg3 v-for="item in filteredUsers" :key="item.id">
      <v-card
        flex
        align="center"
        outlined
       
        class="pa-4 ma-2"
        @click="usersDetail(item.id)"
      >
        <v-list-item-avatar color="white" size="72">
          <v-img contain :src="item.avatar" class="avatar" />
        </v-list-item-avatar>
        <v-list-item-title class="text-h5 mb-1">
          {{ item.firstname }} {{ item.lastname }}
        </v-list-item-title>
        <v-list-item-subtitle v-html="userInfo(item.tenants.find((t) => t.id === current.id))">
         
        </v-list-item-subtitle>
      </v-card>
    </v-flex>
        <v-spacer />
    
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: ["search"],
  methods: {
    usersDetail(id) {
      this.$router.push({ name: "UsersDetail", params: { id: id } });
    },
    userInfo(data) { 
      let strDefault = "";
      let joiner = "";
      if (this.divisions.length > 1) {
        strDefault = "<strong color='primary'>alle Units</strong> <span class='d-none'>" + this.divisions.map(d => d.name).join(" ") + "</span></span>";
        joiner = " - ";
      }
      let unitzugriff = strDefault;
       if (typeof data.permissions.FC_DIVISION_READ === 'object') {
         unitzugriff = [];
         data.permissions.FC_DIVISION_READ.map(x => x.division_id).forEach(division_id => {
           let division = this.divisions.find(item => item.id == division_id);
           if (division) 
              unitzugriff.push(division.name)
              else
              unitzugriff.push(division_id)
         })

          
          if (unitzugriff.length ===  this.divisions.length) {
            unitzugriff = strDefault + '(ggf. unterschiedl. Rechte)';
          } else if (unitzugriff.length > 3) {
            unitzugriff = unitzugriff.length + '/' + this.divisions.length + ' Units'
          } else {
            unitzugriff = unitzugriff.join(", ");
          }
       }
      return data.role + joiner + unitzugriff;
    }
  },
  computed: {
    filteredUsers() {
        return this.users
                    .filter(u => [u.firstname, u.lastname, u.mail, this.userInfo(u.tenants.find(t => t.id === this.current.id))].join(" ").toLowerCase().indexOf((this.search || '').toLowerCase()) > -1)
                    .sort((a,b) => a.lastname.localeCompare(b.lastname));

    },
    ...mapGetters(["current","users","divisions"])
  },
  created(){
  }
};
</script>

<style>
</style>