<template>
  <v-container>
    <confirm ref="confirm" />
    <v-row>
      <v-col cols="1">
        <v-list-item-avatar color="white" size="72">
          <v-img contain :src="user.avatar" />
        </v-list-item-avatar>
      </v-col>
      <v-col>
        <h1>{{ user.firstname }} {{ user.lastname }}</h1>
        <div>{{ user.email }}</div>
        <div>
          Rolle: <strong>{{ user.current.role }}</strong>
        </div>
        <small
          >User ID: {{ user.id }} // last seen:
          {{ lastLogin(user.last_login) }}</small
        >
        <v-divider class="my-3 py-3"></v-divider>

        <v-select
          v-model="selected.role_id"
          :items="roles"
          item-text="name"
          item-value="id"
          label="Neue Benutzerrolle zuweisen"
          outlined
          @change="roleChange()"
        ></v-select>
        <div>
          <h3>Berechtigungen</h3>
          <p>{{ selected.role.description }}</p>
          <v-card flat class="pa-2">
            <v-chip
              small
              label
              class="ma-1"
              v-for="p in permissions.filter(
                (item) => selected.role.permissions.indexOf(item.property) > -1
              )"
              :class="p.sortorder.substr(0, 1)"
              :key="p.property"
              >{{ p.description }} </v-chip
            >
          </v-card>
        </div>

        <v-divider class="my-3 py-3"></v-divider>
        <v-radio-group v-model="allTrue" v-if="divisions.length > 1">
          <v-radio
            label="Unitforecast-Berechtigungen gelten für alle (und zukünftige) Units"
            :value="true"
          ></v-radio>
          <v-radio
            label="Unitforecast-Berechtigungen gelten nur für bestimmte Units"
            :value="false"
          ></v-radio>
        </v-radio-group>

        <div v-if="!allTrue">
          <v-row v-for="(item, index) in selected.restrictions" :key="index">
            <v-col cols="1">
              <v-btn @click="removeRestriction(index)" icon
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </v-col>
            <v-col cols="3">
              <v-select
                label="Unit auswählen"
                v-model="item.division_id"
                dense
                outlined
                hide-details
                item-text="name"
                item-value="id"
                :items="availableDivisions(item.division_id)"
                :rules="[(v) => !!v || 'Division is required']"
                required
                @change="getDivisionClients(item.division_id)"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                label="Berechtigungen"
                v-model="item.permissions"
                dense
                outlined
                hide-details
                multiple
                :items="
                  permissions.filter(
                    (p) => p.property.substr(0, 12) === 'FC_DIVISION_'
                  )
                "
                item-text="label"
                item-value="property"
                item-disabled="disabled"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                label="alle Kunden"
                v-model="item.clients"
                dense
                outlined
                hide-details
                multiple
                :items="getDivisionClients(item.division_id)"
                :disabled="allowCommit(item.permissions) === true"
                item-text="name"
                item-value="id"
              >
              <template v-slot:prepend-item>
                  <v-list-item>
                  Kunden in dieser Unit ({{ $store.getters.forecasts.find(f => f.id === $store.state.activeForecastId).title }})
                  </v-list-item>
              </template>
              </v-select>
            </v-col>
          </v-row>
        </div>
        <v-btn v-if="!allTrue" @click="addRestriction" icon
          ><v-icon>mdi-plus</v-icon></v-btn
        >
        <v-divider v-if="!allTrue"></v-divider>
        <p class="ma-4" v-if="!allTrue">
          Um den Zugriff auf ausgewählte Kunden zu beschränken, darf für die
          entsprechende Unit die Berechtigung "melden" nicht gesetzt sein!
        </p>
        <v-divider class="my-3 py-3"></v-divider>
        <v-row class="mb-12">
          <v-btn depressed color="grey lighten-2" @click="deleteUser"
            >Benutzer entfernen…</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="cancel">{{ trl("cancel") }}</v-btn>
          <v-btn class="ml-2" color="primary" @click="save">{{
            trl("save changes")
          }}</v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { trl } from "@/utils/strings";
import { isLocked } from "@/utils/dates";
import { mapGetters } from "vuex";
import { d2s } from "@/utils/dates";
import confirm from "@/components/modals/Confirm.vue";

export default {
  data() {
    return {
      allTrue: true,
      user: {},
      selected: {},
    };
  },
  computed: {
    ...mapGetters([
      "current",
      "users",
      "divisions",
      "clients",
      "roles",
      "permissions",
    ]),
    
  },

  components: { confirm },
  methods: {
    allowCommit(perms) {
      return perms.indexOf("FC_DIVISION_COMMIT") > -1;
    },
    roleChange() {
      this.selected.role = this.roles.find(
        (item) => item.id === this.selected.role_id
      );
    },
    addRestriction() {
      this.selected.restrictions.push({
        tenant_id: this.current.id,
        user_id: this.user.id,
        division_id: null,
        permissions: this.selected.role.permissions.filter(
          (p) => p.substr(0, 12) === "FC_DIVISION_"
        ),
        clients: null,
      });
    },
    removeRestriction(index) {
      this.selected.restrictions.splice(index, 1);
    },
    availableDivisions(myDivision){
        let items = [];
        let alreadyAssigned = this.selected.restrictions.map(x => x.division_id);

        this.divisions.forEach(d => {

          if(d.deleted_at) return;

          // if (isLocked(d, new Date().getFullYear(), "future")) {
          //   return;
          // }

          items.push({
            id: d.id,
            name: d.name,
            disabled: myDivision !== d.id && alreadyAssigned.indexOf(d.id) > -1
          })
        })

        return items;

    },
    getDivisionClients(id) {
     
      let previouslyAssignedClients = [];

      if(typeof this.user.current.permissions.FC_DIVISION_READ === "object"){
        let x = this.user.current.permissions.FC_DIVISION_READ.find(x => x.division_id === id);
        if (x) {
          previouslyAssignedClients = x.clients || [];
        }
      }
      return this.clients.filter(
        (item) =>
          (item.divisions && item.divisions.indexOf(id) > -1) || item.id === 0 || previouslyAssignedClients.includes(item.id)
      );
    },
    async deleteUser() {
      const confirmed = await this.$refs.confirm.open(
        "Benutzer entfernen",
        `Soll ${this.user.firstname} ${this.user.lastname} aus dem Forecast ${this.current.name} entfernt werden?`,
        { confirmLabel: "Löschen", color: "primary", width: 500 }
      );

      if (confirmed) {
        const del = await this.$store.dispatch("req", {
          route: "user",
          method: "DELETE",
          data: { tenant: this.current.id, id: this.user.id },
        });
        // falls jmd so bloed ist und sich selbst loescht...
        if (this.user.id === this.$store.state.me.id) {
          this.$store.commit("logout");
          return;
        }

        await this.$store.dispatch("req", {
          route: "users",
          mutation: "users",
        });
        this.$router.push("./");
      }
    },
    cancel() {
      this.$router.replace("./");
    },
    async save() {
      if (this.allTrue) {
        this.selected.restrictions = [];
      }

      let data = {
        id: this.user.id,
        tenant_id: this.current.id,
        user_id: this.user.id,
        role_id: this.selected.role_id,
        restrictions: this.selected.restrictions,
      };

      await this.$store.dispatch("saveUser", data);
      this.$router.push("./");
    },
    lastLogin(ts) {
      if (!ts) return "---";
      return d2s(ts, 'short');
    },
    trl,
  },
  created() {
    this.user = this.users.find((u) => u.id === Number(this.$route.params.id));

    this.user.current = this.user.tenants.find((t) => t.id === this.current.id);

    this.selected = {
      role_id: this.user.current.role_id,
      role: this.roles.find((item) => item.id === this.user.current.role_id),
      restrictions: [],
    };

    let ctrPerms = 0;
    let simplyTrue = 0;

    for (let key in this.user.current.permissions) {
      if (key.indexOf("FC_DIVISION_") === -1) {
        continue;
      }
      ctrPerms++;

      if (typeof this.user.current.permissions[key] === "object") {
        this.user.current.permissions[key].forEach((d) => {
          let index = this.selected.restrictions.findIndex(
            (item) => item.division_id === d.division_id
          );
          if (index === -1) {
            this.selected.restrictions.push({
              tenant_id: this.current.id,
              user_id: this.user.id,
              division_id: d.division_id,
              permissions: [key],
              clients: d.clients,
            });
          } else {
            this.selected.restrictions[index].permissions.push(key);
          }
        });
      } else {
        simplyTrue++;
      }
    }

    this.allTrue = ctrPerms === simplyTrue;

    if (this.allTrue) {
      this.addRestriction(); // leeres Element generieren, damit nicht nur ein einsames "plus" da steht
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-chip.A {
  background-color: #000;
  .v-chip__content {
    color: #fff;
  }
}
::v-deep .v-chip.B {
  background-color: var(--v-primary-base);
  .v-chip__content {
    color: #fff;
  }
}

::v-deep .v-chip {
  &.C,
  &.D,
  &.E {
    background-color: #a0a0a0;
    .v-chip__content {
      color: #fff;
    }
  }
}

::v-deep .v-chip {
  &.F,
  &.G,
  &.H {
    background-color: tomato;
    .v-chip__content {
      color: #fff;
    }
  }
}

::v-deep .v-input--radio-group--column .v-input--radio-group__input {
  width: auto;
}

</style>