<template>
  <v-container>
    <v-row class="mt-4 mb-6">
      <v-col>
        <h1>{{ trl('USERS_READ_menu') }} </h1>
      </v-col>
    <v-spacer></v-spacer>
    <v-col cols="3">
 <v-text-field
          class="mt-2"
          v-show="!$route.params.id"
          v-model.trim="search"
          clearable
          prepend-inner-icon="mdi-magnify"
          outlined
          dense
          autofocus
        ></v-text-field>
    </v-col>  
    </v-row>
    <FindUser v-if="current.permissions.USERS_WRITE && !$route.params.id" />
    <Users :search="search" v-if="!$route.params.id" class="mb-10" />
    <SingleUser v-else />
  </v-container>
</template>

<script>
import { trl } from "@/utils/strings";
import { mapGetters } from "vuex";
import store from "@/store";
import FindUser from "@/components/forms/FindUser";
import SingleUser from '@/components/SingleUser.vue';
import Users from '@/components/Users.vue';

export default {
  components: {
    FindUser,
    Users,
    SingleUser
  },
  data() {
    return {
      search: ""
    }
  },
  
  created() {
    this.$store.dispatch("req", {method: 'GET', route: 'users', mutation: 'users'});
  },
  computed: {
    ...mapGetters(["current"])
  },
  methods:{ trl },
  beforeRouteEnter (to, from , next) {
    if (store.getters.current.permissions.USERS_READ) {
      next();
    } else {
      next({name: 'NotFound'});
    }
  }
};
</script>

<style>
</style>